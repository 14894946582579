import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Recurring() {
  return (
    <>
      <Header />
      <main className="min-h-[calc(100vh-125px)] h-full lg:min-h-full">
        <div className="max-w-[1124px] px-4 pt-[144px] pb-16 mx-auto flex items-start lg:flex-col lg:pt-14 lg:pb-16">
          <article>
            <h1 className="font-bold">Recurring Policy</h1>
            <h2 className="font-semibold">SUBSCRIPTIONS</h2>
            <p>
              By purchasing iGeekSoftware Subscription, you agree to an initial and
              recurring Subscription fee at the then-current Subscription rate,
              and you accept responsibility for all recurring charges until you
              cancel your subscription. You may cancel your Subscription at any
              time, subject to the terms of our cancellation policy.
            </p>
            <h2 className="font-semibold">TRIALS:</h2>
            <p>
              Subscription to the iGeekSoftware Service may begin with a $0,99 trial
              period, during which you can try out the Service for 1 (one) day
              from the date you place the order (hereinafter – the “Trial”).
            </p>
            <p>
              You are required to enter your billing information in order to
              place the order and subscribe for the Trial.
            </p>
            <p>
              Upon the expiry of the Trial, you will automatically be charged
              for the reduced price of the Basic package and next subscription
              plan unless you turn off the auto-renewal in your user space.
            </p>
            <p>
              You may cancel your subscription at any time during the Trial.
            </p>
            <h2 className="font-semibold">AUTOMATIC RENEWAL TERMS:</h2>
            <p>
              Once you subscribe iGeekSoftware will automatically process your
              Subscription fee in the next billing cycle. iGeekSoftware offers
              following billing cycles: 2 weeks, 1 month, 3 months, 12 months.
              Billing cycle of 2 weeks consists of 14 calendar days. 1 month
              billing cycle consists of 28 calendar days, this applies only to
              1-, 3- and 6-months subscription plans. 12-months subscription
              plan lasts one calendar year (366 days). iGeekSoftware will continue to
              automatically process your Subscription fee at the then-current
              Subscription rate, until you cancel your subscription. We explain
              how to cancel your Subscription below at the Section Cancellation
              Policy.
            </p>
            <p>
              You may cancel or freeze your Subscription at any time by logging
              into your User Space Account, clicking on your Login email in the
              top right corner of the User Space and simply click “deactivate”.
              You will be given a choice or to cancel your subscription
              completely or to freeze it until you will be able to use it. If
              you need help, feel free to email us at{" "}
              <a
                href="mailto:support@igeeksoftware.com"
                className="text-orange-light hover:underline"
              >
                support@igeeksoftware.com
              </a>
              . If you cancel your Subscription, the cancellation will take
              effect for the next monthly billing cycle. You will not be
              eligible for a refund for monthly subscription fees paid prior to
              the month the cancellation takes effect. If you cancel your
              subscription you can re-subscribe any time.
            </p>
          </article>
        </div>
      </main>
      <Footer />
    </>
  );
}
