import React, { Component } from "react";

import { Route, Routes } from "react-router-dom";
import Index from "../page";
import Pricing from "../page/pricing";
import Privacy from "../page/privacy-policy";
import Eula from "../page/eula";
import Contact from "../page/contact-us";
import Cookie from "../page/cookie-notice";
import Recurring from "../page/recurring-policy";
import Refund from "../page/refund-policy";
import Terms from "../page/terms-of-service";
import Middle from "../page/middle";
import Payment from "../page/payment";

export default class index extends Component {
  render() {
    return (
      <Routes>
        <Route element={<Index />} path="/"></Route>
        <Route element={<Pricing />} path="/pricing"></Route>
        <Route element={<Privacy />} path="/privacy-policy"></Route>
        <Route element={<Eula />} path="/eula"></Route>
        <Route element={<Contact />} path="/contact-us"></Route>
        <Route element={<Cookie />} path="/cookie-notice"></Route>
        <Route element={<Recurring />} path="/recurring-policy"></Route>
        <Route element={<Refund />} path="/refund-policy"></Route>
        <Route element={<Terms />} path="/terms-of-service"></Route>
        <Route element={<Middle />} path="cart/:id"></Route>
        <Route element={<Payment />} path="payment/:id"></Route>
      </Routes>
    );
  }
}
