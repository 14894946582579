interface ButtonProps {
  link?: string;
  text?: string;
  className?: string;
  buttonClass?: string;
}

export default function Button({
  link,
  text,
  className,
  buttonClass,
}: ButtonProps) {
  return (
    <>
      <div className={`${className}`}>
        <a
          className={`bg-gradient-to-r from-[#FA676C] to-[#F87556] rounded text-white text-xl font-extrabold px-8 py-[14px]  hover:cursor-pointer  ${buttonClass}`}
          href={link}
        >
          {text}
        </a>
      </div>
    </>
  );
}
