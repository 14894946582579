import { useState,useMemo } from "react";
import minspyLogo from '../assets/minspy_logo.svg';
// import spyicLogo from '../assets/spyic_logo.svg';
import neatspyLogo from '../assets/neatspy_logo.svg';
import spyierLogo from '../assets/spyier_logo.svg';
import spyzieLogo from '../assets/spyzie_logo.svg';
import spyineLogo from '../assets/spyine_logo.svg';
import clickfreeLogo from '../assets/clickfree_logo.svg';
import safespyLogo from '../assets/safespy_logo.svg';

const cocospyLogo = require('../assets/cocospy.png');
const spyicLogo = require('../assets/spyic_logo.png');

const topList = [
  { text: "Home", link: "/" },
  { text: "Pricing", link: "/pricing" },
  { text: "Contact Us", link: "/contact-us" },
  { text: "Privacy Policy", link: "/privacy-policy" },
  { text: "Terms of Service", link: "/terms-of-service" },


];

interface PropsType {
  navVisibility?: boolean; //判断是否渲染链接，默认为真
  website?:string;
}

export default function Header({ navVisibility = true,website = '' }: PropsType) {
  const [visible, setVisible] = useState(true);
  const [dropVisible, setdropVisible] = useState(true);

  const navFun = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setVisible(!visible);
  };

  const navDropFun = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setdropVisible(!dropVisible);
  };
  const onMouseEnter = () => {
    const width = document.body.scrollWidth;
    if (width > 1024) {
      setdropVisible(false);
    }
  };
  const image = useMemo(() => {
		switch (website) {
			case 'cocospy':
				return cocospyLogo;
			case 'spyic':
				return spyicLogo;
			case 'minspy':
				return minspyLogo;
			case 'spyier':
				return spyierLogo;
			case 'spyzie':
				return spyzieLogo;
			case 'spyine':
				return spyineLogo;
			case 'neatspy':
				return neatspyLogo;
			case 'safespy':
				return safespyLogo;
			case 'clickfree':
				return clickfreeLogo;
			default:
				return require('../assets/top_logo@2x.png');
		}
	}, [website]);
  return (
    <>
      <header className={`fixed w-full ${(website && website !== 'fotosoft')?'bg-white text-black':'bg-black text-white'} z-10 shadow-block`}>
        <nav className="flex items-center justify-between w-full max-w-[1200px] mx-auto h-16 xl:px-4 lg:h-14 lg:flex-row">
          <a href="/" className="flex items-center">
            <img src="/images/logo.svg" alt="Header Banner" />
            {website && website !== 'fotosoft' ? (
								<span className="border-l border-[#606367] pl-2 ml-2 block">
									<img src={image} className="h-[18px]" alt="subSite logo" />
								</span>
							) : null}
          </a>
          {navVisibility ? (
            <>
            <div
              className={`bread-nav hidden lg:flex lg:mr-[14px]`}
              onTouchStart={navFun}
            >
              {visible ? (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10.6667H24"
                    stroke="#606367"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M8 16H24"
                    stroke="#606367"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M8 21.3335H24"
                    stroke="#606367"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.666 21.3334L21.3327 10.6667"
                    stroke="#606367"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M21.3327 21.3333L10.666 10.6666"
                    stroke="#606367"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
            <ul
              className={`font-bold flex space-x-14 text-black items-center lg:bg-white  lg:px-5 lg:text-black lg:w-full lg:absolute lg:left-0 lg:top-14 lg:flex-col lg:space-x-0 lg:z-[1] lg:h-[calc(100vh-56px)] lg:overflow-y-auto ${
                visible ? "lg:hidden" : ""
              }`}
            >
              {topList.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={navDropFun}
                    onMouseOver={() => onMouseEnter()}
                    className="group shrink-0 lg:w-full lg:pt-5"
                  >
                    <a className="hover:text-orange-light" href={item.link}>
                      {item.text}
                    </a>
                  </li>
                );
              })}
              <li className="bg-gradient-to-r from-[#FA676C] to-[#F87556] lg:flex hidden justify-center w-full text-white shadow-card rounded py-3 lg:mt-5 hover:bg-orange-dark hover:shadow-card2">
                <a href="/pricing">Start Writing</a>
              </li>
            </ul>
           </>): null}
        </nav>
      </header>
    </>
  );
}
