interface PropsType {
  website?: boolean; // 判断页面需不需要渲染底部
  bgHeight?: boolean; // 判断页面底部需不要要加高度
  bgColor?: string; // 设置页面背景色，默认为bg-black-light
}

const footList = [
  { text: "Eula", link: "/eula" },
  { text: "Privacy Policy", link: "/privacy-policy" },
  { text: "Terms of Service", link: "/terms-of-service" },
  { text: "Refund Policy", link: "/refund-policy" },
  { text: "Recurring Policy", link: "/recurring-policy" },
  { text: "Cookie Notice", link: "/cookie-notice" },
  { text: "Contact Us", link: "/contact-us" },
];

export default function Footer({
  website = true,
  bgHeight = true,
  bgColor = "bg-black-light",
}: PropsType) {
  return (
    <>
      <footer className={`${bgColor} ${bgHeight ? "" : "h-[137px] lg:h-full"}`}>
        {website ? (
          <div className=" py-6 xl:px-4 lg:py-4">
            <div className="w-full text-center text-xs text-opacity-[60%]  text-white lg:text-left">
              <p>
                Copyright © 2023 iGeekSoftware. All trademarks are the property
                of their respective owners.
              </p>
            </div>
            <div className="text-white flex items-center gap-x-8 justify-center mt-6 pt-3 border-[#9F9AAE] border-opacity-[20%] border-t max-w-[1200px] mx-auto lg:flex-col lg:text-left lg:items-start lg:gap-y-2">
              {footList.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item.link}
                    className="text-sm font-semibold hover:underline"
                  >
                    {item.text}
                  </a>
                );
              })}
            </div>
          </div>
        ) : null}
      </footer>
    </>
  );
}
