import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import countryList from "../data/national.json";
import toolList from "../data/tools.json";

export default function Index() {
  return (
    <>
      <Header />
      <main className="pt-16 lg:pt-14 leading-7 isolate bg-[#F2F2F2] relative z-[2]">
        <section className="relative overflow-hidden min-h-[1038px] text-white  lg:min-h-full xl:pb-20 md:pb-0">
          <div className="relative z-[2]">
            <div className="max-w-[800px] w-full relative text-center  mx-auto mt-[128px] lg:px-5  lg:mt-12  sm:mt-6">
              <h1 className="text-[64px] leading-[72px] font-extrabold lg:text-[32px] lg:leading-[48px]">
                A Racy Way to Generate AI Copies is just a Click Away.
              </h1>
              <p className="mt-6 max-w-[640px] w-full mx-auto text-lg lg:mt-3 lg:text-base">
                Using our powerful AI content generator, you can make great
                sales copy, ads, product descriptions, video scripts, blogs,
                emails, and more.
              </p>
              <Button
                buttonClass="flex justify-center items-center hover:before:absolute hover:before:animate-ping hover:before:rounded-full hover:before:w-[160px] hover:before:h-[160px] hover:before:bg-white hover:before:opacity-25"
                className="pt-8 max-w-[223px] mx-auto w-full lg:pb-10"
                text="Start Writing"
                link="/pricing"
              />
            </div>
          </div>
          <figure className="absolute top-0 left-0 right-0 bottom-0">
            <img
              src="/images/bg.jpg"
              className="object-cover w-full h-full"
              alt="background img"
            />
          </figure>
        </section>
        <section className="-mt-[429px] relative z-[4] max-w-[1200px] w-full mx-auto px-4 lg:-mt-20 md:mt-5 md:px-3">
          <div className="bg-white p-8 shadow-card3">
            <figure className="w-full">
              <img
                src="/images/form.png"
                alt="A Racy Way to Generate AI Copies is just a Click Away."
                className="w-full h-full object-cover"
              />
            </figure>
          </div>
        </section>
        <section className="mt-40 max-w-[1200px] mx-auto w-full lg:px-3 lg:mt-10">
          <h2 className="text-5xl max-w-[900px] mx-auto w-full text-center font-extrabold lg:text-2xl">
            Generate outstanding human-readable write-ups in a blink.
          </h2>
          <ul className="grid grid-cols-2 gap-10 mt-10 lg:mt-5 lg:gap-y-5 lg:grid-cols-1">
            {[
              {
                img: "/images/power.svg",
                title: "Power and Simplicity Combined",
                desc: (
                  <>
                    It functions like your favorite copywriter, who writes
                    stunning copies with a few clicks.
                  </>
                ),
              },
              {
                img: "/images/tools.svg",
                title: "More Tools",
                desc: (
                  <>
                    Get spoilt with everything you need to get that web copy,
                    product description, ad copy, etc.
                  </>
                ),
              },
              {
                img: "/images/integrated.svg",
                title: "Integrated AI",
                desc: <>GPT-3 AI powers the artificial intelligence</>,
              },
              {
                img: "/images/supports.svg",
                title: "Supports more Than 100 Languages",
                desc: (
                  <>English, Spanish, French, German, and more are welcome.</>
                ),
              },
              {
                img: "/images/create.svg",
                title: "Create Copies Quickly",
                desc: (
                  <>
                    It's effortless to use iGeekSoftware's AI power when writing
                    content. Let it think and generate the content for you.
                  </>
                ),
              },
              {
                img: "/images/delivering.svg",
                title: "Committed to Delivering",
                desc: (
                  <>
                    Transform your copywriting drive into workable results,
                    increase conversion, and push your business to the next
                    level with iGeekSoftware.
                  </>
                ),
              },
            ].map((item, index) => {
              return (
                <li key={index} className="bg-white p-8 shadow-card3 lg:p-5">
                  <figure className="w-16 h-16">
                    <img src={item.img} alt={item.title} />
                  </figure>
                  <h3 className="text-xl font-extrabold mt-3 lg:text-lg">
                    {item.title}
                  </h3>
                  <p className="mt-2 text-sm">{item.desc}</p>
                </li>
              );
            })}
          </ul>
        </section>
        <section className="mt-40 max-w-[1200px] mx-auto w-full lg:px-3 lg:mt-10">
          <div className="bg-white p-12 shadow-card3 flex justify-between space-x-[72px] lg:p-5 lg:flex-col lg:space-x-0 lg:space-y-5 ">
            <figure className="w-[520px] lg:mx-auto sm:w-full bg-[#ededfb]">
              <img
                src="/images/what-our.jpg"
                alt="What our customers get from iGeekSoftware"
                className="object-cover w-full h-full"
              />
            </figure>
            <div className="flex-1">
              <h2 className="text-5xl font-extrabold lg:text-2xl">
                What our customers get from iGeekSoftware
              </h2>
              <ul className="mt-5 space-y-5">
                {[
                  {
                    title: "Generating Ad Copy",
                    desc: (
                      <>
                        iGeekSoftware has cutting-edge AI technology to create
                        search engine and social media ads. It assumes the role
                        of a team of writers and proceeds to write your
                        niche-related ad copy.
                      </>
                    ),
                  },
                  {
                    title: "E-commerce Product Descriptions",
                    desc: (
                      <>
                        Those in e-commerce spend countless hours generating
                        titles and descriptions for their products. The
                        entrepreneurs who utilize the power of iGeekSoftware
                        have more time for other business matters. You can
                        produce more incredible product descriptions that
                        attract the proper conversion.
                      </>
                    ),
                  },
                  {
                    title: "Video Scripts & Sales Copy",
                    desc: (
                      <>
                        Automatically generate a fascinating video script or
                        sales copy without needing a writer. iGeekSoftware will
                        get you that email, landing page, sales letter, and
                        video script content. It will do that by studying your
                        brand and what the description says to create the best
                        phrases and sentences that lure customers toward you.
                      </>
                    ),
                  },
                ].map((v, i) => {
                  return (
                    <li className="space-y-2" key={i}>
                      <h3 className=" font-extrabold">{v.title}</h3>
                      <p className="lg:text-sm">{v.desc}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
        <section className="mt-40 max-w-[1200px] mx-auto w-full lg:px-4 lg:mt-10">
          <div className="bg-white shadow-card3 p-12 lg:p-5">
            <h2 className="text-2xl font-extrabold text-center">
              iGeekSoftware supports the following languages:
            </h2>
            <ul className="mt-5 flex flex-wrap justify-center text-left w-[1092px] gap-3 lg:w-full">
              {countryList.map((item, index) => {
                return (
                  <li key={index} className="space-x-2 justify-start flex items-center">
                    <figure className="w-6 h-4">
                      <img src={item.img} alt="Country" />
                    </figure>
                    <p>{item.name}</p>
                  </li>
                );
              })}
              <li>
                <p className="text-center">and more.</p>
              </li>
            </ul>
            <Button
              className="pt-5 flex justify-center"
              buttonClass="hover:bg-orange-dark hover:shadow-card2"
              text="Start Writing"
              link="/pricing"
            />
          </div>
        </section>
        <section className="mt-40 max-w-[1200px] mx-auto w-full lg:px-4 lg:mt-10">
          <div>
            <div className="flex items-center justify-between gap-x-12 lg:flex-col lg:space-y-3">
              <h2 className="text-5xl max-w-[576px] mx-auto w-full leading-[64px] font-extrabold lg:text-2xl lg:text-center">
                The AI Copy generator every user needs
              </h2>
              <p className="lg:text-sm lg:text-center">
                iGeekSoftware is your copywriter buddy with the content you
                require.
              </p>
            </div>
            <ul className="mt-10 grid grid-cols-2 gap-x-12 gap-y-10 lg:gap-y-5 lg:grid-cols-1">
              {[
                {
                  img: "/images/marketers.jpg",
                  title: "Marketers",
                  desc: (
                    <>
                      It's the copywriting software for your marketing needs.
                      You don't have to write your offers' landing pages and
                      emails. Simply generate 100 of them or more in minutes
                      with a few clicks.{" "}
                    </>
                  ),
                },
                {
                  img: "/images/founders.jpg",
                  title: "Founders",
                  desc: (
                    <>
                      Take advantage of the power and simplicity presented here.
                      It's for founders who require intelligent software to give
                      them a well-written copy in almost no time.{" "}
                    </>
                  ),
                },
                {
                  img: "/images/writers.jpg",
                  title: "Writers",
                  desc: (
                    <>
                      Writers have been searching for such software for the
                      longest time. There is more creativity in this tool to
                      write infinitely for you than the writer you aspire to be.{" "}
                    </>
                  ),
                },
                {
                  img: "/images/creators.jpg",
                  title: "Creators",
                  desc: (
                    <>
                      Content creation is a tedious task that consumes a lot of
                      time. With iGeekSoftware's AI intelligence, you can
                      offload such a burden by utilizing copywriting software
                      that helps create engaging content.
                    </>
                  ),
                },
              ].map((item, index) => {
                return (
                  <li key={index} className="bg-white p-8 lg:p-5">
                    <figure>
                      <img
                        src={item.img}
                        alt={item.title}
                        className=" object-cover w-full h-full"
                      />
                    </figure>
                    <h3 className="text-xl font-extrabold mt-5 lg:text-lg">
                      {item.title}
                    </h3>
                    <p className="mt-2 text-sm">{item.desc}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className="mt-40 max-w-[1200px] mx-auto w-full lg:px-3 lg:mt-10">
          <div className="flex items-center gap-x-12 lg:flex-col">
            <div className="flex-1">
              <h2 className="text-5xl leading-[64px] font-extrabold lg:text-2xl lg:text-center">
                What the customers have to say about iGeekSoftware
              </h2>
              <div className="bg-white mt-12 lg:mt-3 p-8 lg:p-5 shadow-card3">
                <figure className="w-16 h-16">
                  <img src="/images/people01.jpg" alt="People Banner" />
                </figure>
                <p className="text-xl font-extrabold mt-3">
                  “It's an honor to write this congratulatory message for
                  developing such a tool. I'm currently hooked on the monthly
                  subscription, but I'm aiming for the yearly one. Keep doing
                  the great work, and you are definitely recommendable.”
                </p>
                <p className="mt-3">
                  <span className="font-semibold">Emma Vance</span>, Marketer
                </p>
              </div>
            </div>
            <div className="flex-1 lg:mt-3">
              <ul className=" space-y-10 lg:space-y-3">
                {[
                  {
                    img: "/images/people02.jpg",
                    desc: (
                      <>
                        “Quite a stunner right there! I have been able to save
                        hours of writing for my business enterprise.”
                      </>
                    ),
                    name: "Charles Reece",
                    job: "CEO",
                  },
                  {
                    img: "/images/people03.jpg",
                    desc: (
                      <>
                        “This software is nothing short of amazing. I now don't
                        need a copywriter for my content.”
                      </>
                    ),
                    name: "Martin Hamza",
                    job: "Content Creator",
                  },
                  {
                    img: "/images/people04.jpg",
                    desc: (
                      <>
                        “I now use minimal time to create the content I require.
                        iGeekSoftware is suited for landing page copies, ads,
                        and anything else I want to put into words.”
                      </>
                    ),
                    name: "Lillian Ruby",
                    job: "CO-Founder",
                  },
                ].map((item, index) => {
                  return (
                    <li
                      className="bg-white p-8 lg:p-5 shadow-card3"
                      key={index}
                    >
                      <figure className="w-16 h-16">
                        <img src={item.img} alt="People Banner" />
                      </figure>
                      <p className="text-xl font-extrabold mt-3">{item.desc}</p>
                      <p className="mt-3">
                        <span className="font-semibold">{item.name}</span>,
                        {item.job}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
        <section className="mt-40 max-w-[1200px] mx-auto w-full lg:px-3 pb-24 lg:pb-8 lg:mt-10">
          <div className="bg-white p-12 lg:p-5">
            <h2 className="text-5xl max-w-[900px] w-full mx-auto text-center font-extrabold lg:text-2xl">
              The Impressive Copywriting Tools at Your Disposal
            </h2>
            <p className="mt-3 max-w-[860px] mx-auto text-center w-full lg:text-sm">
              iGeekSoftware gets inspiration from copywriting and conversion
              experts who dedicate their time to producing excellent content for
              attention and conversion purposes. More tools are coming up this
              month.
            </p>
            <ul className="mt-10 grid grid-cols-3 gap-x-[18px] gap-y-5 lg:mt-3 lg:gap-y-3 lg:grid-cols-1">
              {toolList.map((item, index) => {
                return (
                  <li className="p-7 bg-[#F8F9FD] lg:p-5" key={index}>
                    <h3 className="text-lg font-extrabold lg:text-base">
                      {item.title}
                    </h3>
                    <p className="mt-2 lg:text-sm">{item.text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
