import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const list = [
  { text: "Access To All Tools" },
  { text: "Save All AI Generations" },
];
const GreenSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8445 7.68891C17.2602 7.30582 17.9056 7.3245 18.2985 7.73098C18.6894 8.13544 18.6882 8.77725 18.296 9.18035L11.2505 16.4202C10.9436 16.7356 10.4352 16.7297 10.1356 16.4073L6.68834 12.6975C6.30234 12.2821 6.30866 11.6373 6.70273 11.2295C7.09782 10.8207 7.74404 10.7935 8.17217 11.1675L10.6863 13.3642L16.8445 7.68891Z"
        fill="#26BC5E"
      />
    </svg>
  );
};
declare var Paddle: any;





export default function Pricing() {
  const paymentFun = (paddleId:number)=>{
    Paddle.Setup({
      vendor: 165969,
      eventCallback: (data: any) => {
        if (data.event === "Checkout.Complete") {
          console.log(data.eventData); // Data specifics on the event
        } else if (data.event === "Checkout.Close") {
          console.log(data.eventData); // Data specifics on the event
        }
      },
    });
    // 开启paddle
    Paddle.Checkout.open({
      checkoutVariant: {
          isForce: true,
      },
      disableLogout: true,
      product: paddleId, // Replace with your Product or Plan ID
    });
  }

  return (
    <>
      <Header navVisibility={false} />
      <main className="pt-16 lg:pt-14 leading-7 isolate min-h-[calc(100vh-137px)] bg-[#F2F2F2] relative z-[2] lg:min-h-full">
        <div className="mt-16 max-w-[1180px] mx-auto text-center pb-[162px] lg:px-4 lg:pb-10">
          <h1 className="text-[56px] leading-[72px] font-extrabold lg:text-2xl">
            Pricing
          </h1>
          <div className="mt-12 p-3 bg-white max-w-[860px] mx-auto w-full rounded-[28px] shadow-card">
            <div className="grid grid-cols-2 gap-x-3 mt-12 lg:grid-cols-1 lg:gap-y-4 lg:mt-5">
              {[
                {
                  title: "Premium Monthly",
                  bgClass: "bg-[#FAFAFA]",
                  textClass: "",
                  price: "$39.99",
                  desc: <>Generate AI-powered copy in 1 click.</>,
                  topText: "20k AI Generation Credits",
                  paddleId:826973
                },
                {
                  title: "Annual",
                  bgClass: "bg-white",
                  price: "$199.99",
                  textClass: "w-[363px] lg:w-full",
                  desc: <>Generate unlimited AI-powered copy in 1 click.</>,
                  topText: "Unlimited Al Generations",
                  paddleId:826974
                },
              ].map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${item.bgClass} rounded-[20px] px-8 pt-8 pb-10 overflow-hidden text-left hover:bg-[#FAFAFA]`}
                  >
                    <h3 className={`text-xl font-extrabold lg:text-lg`}>
                      {item.title}
                    </h3>
                    <p className="text-base font-extrabold pt-5 lg:pt-2">
                      Starts at
                    </p>
                    <p className="mt-5 text-[48px] leading-[64px] font-extrabold lg:text-xl">
                      {item.price}{" "}
                      <span className="text-4xl leading-[48px]">USD</span>
                    </p>
                    <p
                      className={`text-base font-semibold mt-2 ${item.textClass}`}
                    >
                      {item.desc}
                    </p>
                    <ul className="mt-5 lg:mt-2">
                      <li className="flex items-center gap-x-1 lg:gap-x-[2px]">
                        <GreenSvg />
                        {item.topText}
                      </li>
                      {list.map((v, i) => {
                        return (
                          <li
                            className="flex items-center gap-x-1 lg:gap-x-[2px]"
                            key={i}
                          >
                            <GreenSvg />
                            {v.text}
                          </li>
                        );
                      })}
                    </ul>
                    <span
                      onClick={()=>{paymentFun(item.paddleId)}}
                      className={`py-4 cursor-pointer mt-8 text-center flex max-w-[348px] text-white mx-auto w-full bg-orange-light justify-center text-xl font-extrabold rounded lg:text-lg lg:mt-2 hover:bg-orange-dark hover:shadow-card2`}
                    >
                      Start Writing
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>
      <Footer website={false} bgHeight={false} bgColor="bg-[#F2F2F2]" />
    </>
  );
}
