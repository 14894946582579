import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Privacy() {
  return (
    <>
      <Header />
      <div className="max-w-[1124px] px-4 pt-[144px] pb-16 mx-auto flex items-start lg:flex-col lg:pt-14 lg:pb-16">
        <article>
          <h1 className="font-bold">Privacy Policy</h1>
          <p>
            This Privacy Policy aims to help you have a clearer picture of how
            we collect, use, and disclose your information. When you continue to
            use our services, you give consent to our treatment of your data as
            outlined in this Privacy Policy. If you frown upon the way we handle
            your data, you ought not to use our services.
          </p>
          <h2 className="font-semibold">Data controller and representative</h2>
          <p>
            Through its website (the “Website”) and its desktop applications and
            mobile applications (the “Application”), iGeekSoftware implements
            the processing of personal data listed below.
          </p>
          <p>
            Please read carefully the following privacy policy which provides
            you, as the user of the Website and/or Application (the “User” or
            “You”), with the relevant information regarding the various
            processing of personal data implemented by iGeekSoftware, in
            accordance with GDPR.
          </p>
          <h2 className="font-semibold">
            Data processing implemented by iGeekSoftware
          </h2>
          <p>
            When the User visits to our Website, or purchases a iGeekSoftware
            product through the Website or the Application, or use our products,
            iGeekSoftware implements a processing of personal data as follows:
          </p>
          <h3 className="font-semibold">Personal data collected</h3>
          <p>
            Regarding this processing, iGeekSoftware collects the following
            personal data through the collection forms in the ordering process
            or other processes.
          </p>
          <h3 className="font-semibold">
            Purpose and legal basis of the processing
          </h3>
          <p>
            iGeekSoftware uses the information we collect about you for the
            following purposes:
          </p>
          <p>
            · In order for iGeekSoftware to provide the User with the ordered
            product(s);
          </p>
          <p>
            · For the performance of a contract to which the User is party, in
            accordance with Article 6.1.b of the GDPR and the collected data are
            necessary in order for iGeekSoftware to identify and bill the User
            and to charge its bank card number;
          </p>
          <p>
            · For Verifying your identity and Providing customer service or
            support;
          </p>
          <p>
            · Analyze your use of our websites and products to better understand
            how they are being used so we can improve our services and the user
            experience and engage and retain users.
          </p>
          <h3 className="font-semibold">Recipients of the personal data</h3>
          <p>
            We may also transmit your personal information with companies that
            help us to run our business by processing personal information on
            behalf of us for the purposes identified above. Such companies
            include providers of payment processing services, server services
            providers, data analysis services providers, fraud prevention
            providers, email delivery service providers, social media, and other
            marketing platforms and service providers.
          </p>
          <p>
            In addition, iGeekSoftware may disclose the User's personal data:
          </p>
          <p>
            if iGeekSoftware is under a duty to disclose or share such personal
            data in order to comply with any legal obligation, or in order to
            protect the rights, property or safety of its business, its
            customers or others; and to successors in title or replacement
            operators of all or part of iGeekSoftware's respective businesses.
          </p>
          <h3 className="font-semibold">Transfer of personal data</h3>
          <p>
            The collected personal data may be transferred by iGeekSoftware to
            recipients which are located outside the European Union and such
            destinations may not have laws which protect the User's personal
            data to the same extent as in the European Union.
          </p>
          <p>
            iGeekSoftware ensures that the User's personal data processed by
            iGeekSoftware or by its suppliers and partners operating outside the
            European Union are treated securely and are protected against
            unauthorized access, loss or destruction, unlawful processing and
            any processing which is inconsistent with the purposes set out in
            this privacy policy.
          </p>
          <h3 className="font-semibold">Duration of the storage</h3>
          <p>
            iGeekSoftware will store the collected personal data for the
            duration of the contract and 60 days after the termination of the
            contract. Beyond this duration, in order for iGeekSoftware to be
            able to demonstrate the existence of a right or a contract or a
            legal obligation, the relevant personal data will be stored through
            intermediary archives for a duration that will not exceed what is
            strictly necessary regarding the purpose of the storage and in
            accordance with the applicable legislation.
          </p>
          <p>
            Regarding banking information, said information will be deleted once
            the payment has been processed and has become effective. Withdrawal
            time period could additionally apply. The number and the expiration
            date of the credit card number (not the CVV security code) could be
            stored through intermediary archives for evidence purposes in case
            of a claim regarding the payment, for the duration provided by
            Article L. 133-24 of the French Monetary and Financial Code (13
            months or 15 months in case of deferred charge card).
          </p>
          <h2 className="font-semibold">
            Processing of personal data related to comments areas
          </h2>
          <p>
            When the User decides to comment a iGeekSoftware product through the
            Website or the Application, iGeekSoftware implements a processing of
            personal data as follows:
          </p>
          <h3 className="font-semibold">Personal data collected</h3>
          <p>
            Regarding this processing, iGeekSoftware collects the following
            personal data:
          </p>
          <p>· Email address;</p>
          <p>· Name or nickname;</p>
          <p>· profile photo;</p>
          <p>
            · Other potential personal information provided by the User through
            its comment (age, location, etc.);
          </p>
          <p>· The content of your comment.</p>
          <h3 className="font-semibold">
            Purpose and legal basis of the processing
          </h3>
          <p>
            This processing is implemented by iGeekSoftware in order to improve
            its products and provide the consumers and potential consumers with
            a better and transparent information about the iGeekSoftware
            products. Said comments and the related personal data are provided
            by the User on a voluntary basis so that the processing of the
            collected personal data relies on the User's consent.
          </p>
          <p>
            iGeekSoftware may use the comments (with your relevant information)
            you post for marketing purpose in order for others to better
            understand and use our products.
          </p>
          <h3 className="font-semibold">Recipients of the personal data</h3>
          <p>
            iGeekSoftware may transmit the collected personal data to third
            party email delivery service providers, social medias, other
            marketing platforms and service providers .
          </p>
          <h3 className="font-semibold">Transfer of personal data</h3>
          <p>
            The collected personal data are transferred by iGeekSoftware to
            recipients which are located outside the European Union and such
            destinations may not have laws which protect the User's personal
            data to the same extent as in the European Union.
          </p>
          <p>
            iGeekSoftware ensures that the User's personal data processed by
            iGeekSoftware or by its suppliers and partners operating outside the
            European Union are treated securely and are protected against
            unauthorized access, loss or destruction, unlawful processing and
            any processing which is inconsistent with the purposes set out in
            this privacy policy.
          </p>
          <p>Please find below the relevant information on transfer:</p>
          <p>
            · iGeekSoftware may transfer the collected information to providers
            of third party email delivery service to send promotion emails, to
            other marketing platforms and service providers to complete relevant
            service.
          </p>
          <p>
            · iGeekSoftware may store the collected information on the servers
            leased from providers of third party server service.
          </p>
          <p>
            iGeekSoftware may use third party track tools(such as Google
            Analytics) to track the information on the user's operation to the
            advertising emails, such information will be stored in third party
            servers. Such third party track tools may send the data analysis
            report to iGeekSoftware for iGeekSoftware's above mentione.
          </p>
          <h3 className="font-semibold">Duration of the storage</h3>
          <p>
            iGeekSoftware will store the collected personal data for not more
            than 5 years after the collection.
          </p>
          <h2 className="font-semibold">
            Processing of personal data related to newsletters and advertising
            emails
          </h2>
          <p>
            The User may want to subscribe to iGeekSoftware's newsletter in
            order to be provided with information and news regarding
            iGeekSoftware products. In this case, iGeekSoftware implements a
            processing of personal data as follows:
          </p>
          <h3 className="font-semibold">Personal data collected</h3>
          <p>
            Regarding this processing, iGeekSoftware only collects your provided
            information such as the User's email address, name or nickname,
            country, preferred product, and your operation to the promotion
            emails.
          </p>
          <h3 className="font-semibold">
            Purpose and legal basis of the processing
          </h3>
          <p>
            iGeekSoftware implements the processing of personal data in order to
            provide consumers and potential consumers with information and news
            regarding iGeekSoftware products.
          </p>
          <p>
            The subscription to iGeekSoftware's newsletter and the related
            provision of personal data is made on a voluntary basis so that the
            processing of the collected personal data relies on the User's
            consent.{" "}
          </p>
          <h3 className="font-semibold">Recipients of the personal data</h3>
          <p>
            iGeekSoftware may transmit the collected personal data to providers
            of email delivery serviceand other service providers such as data
            analysis providers and server providers.
          </p>
          <h3 className="font-semibold">Transfer of personal data</h3>
          <p>
            The collected personal data are transferred by iGeekSoftware to
            recipients which are located outside the European Union and such
            destinations may not have laws which protect the User's personal
            data to the same extent as in the European Union.
          </p>
          <p>
            iGeekSoftware ensures that the User's personal data processed by
            iGeekSoftware or by its suppliers and partners operating outside the
            European Union are treated securely and are protected against
            unauthorized access, loss or destruction, unlawful processing and
            any processing which is inconsistent with the purposes set out in
            this privacy policy.
          </p>
          <p>Please find below the relevant information on transfer:</p>
          <p>
            · iGeekSoftware may transfer the collected information to providers
            of email delivery service providers to send newsletters or promotion
            emails.
          </p>
          <p>
            · iGeekSoftware may store the collected information on the servers
            leased from providers of server service.
          </p>
          <p>
            · iGeekSoftware may use third party track tools(such as Google
            Analytics) to track the information on the user's operation to the
            advertising emails, such information will be stored in third party
            servers. Such third party track tools may send the data analysis
            report to iGeekSoftware for iGeekSoftware's above mentioned
          </p>
          <h3 className="font-semibold">Duration of the storage</h3>
          <p>
            iGeekSoftware will store the collected personal data for 3 years
            from their collection or from the last contact from the User
            (whichever is later).
          </p>
          <p>
            At the end of this 3 years time period, iGeekSoftware may contact
            the User in order to know if the User wishes to keep receiving
            commercial information from iGeekSoftware. In case iGeekSoftware
            would not receive any positive and explicit answer from the User,
            the personal data would be deleted or archived in accordance with
            the applicable legislation.
          </p>
          <h2 className="font-semibold">Cookies and trackers</h2>
          <p>
            When the User consults the Website and/or the Application,
            iGeekSoftware implements cookies and other trackers on the User's
            device.
          </p>
          <p>
            For additional information on cookies and trackers, please read
            iGeekSoftware's cookies policy.
          </p>
          <h2 className="font-semibold">Revision of this Privacy Policy</h2>
          <p>
            Occasionally, we may change this privacy policy (or other documents
            related to privacy policy) to allow iGeekSoftware to use or share
            your personal data in a different way. If we do, the links to the
            policy on our websites (which are generally found in the footer of
            the website) will indicate that the policy has been changed. For new
            users, the change will become effective upon posting. For existing
            users, if the change is significant, it will become effective 30
            days after posting. We encourage you to periodically review the
            privacy policy for the latest information on our privacy practices.
          </p>
          <h2 className="font-semibold">Contacting iGeekSoftware</h2>
          <p>
            If the User needs to contact iGeekSoftware for any reason (including
            to exercise any of its rights in relation to data protection as set
            out above) please contact{" "}
            <a
              href="mailto:support@igeeksoftware.com"
              className="text-orange-light hover:underline"
            >
              support@igeeksoftware.com
            </a>
            .
          </p>
        </article>
      </div>
      <Footer />
    </>
  );
}
