import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Terms() {
  return (
    <>
      <Header />
      <div className="max-w-[1124px] px-4 pt-[144px] pb-16 mx-auto flex items-start lg:flex-col lg:pt-14 lg:pb-16">
        <article>
          <h1 className="font-bold">Terms of Service</h1>
          <p>
            These Terms of Service describe the legally binding terms and
            conditions between iGeekSoftware and a site user (referred to as “You”,
            “Your”, “User”, “Users”, “Website User”). All such additional terms,
            guidelines, and rules are incorporated by reference into these
            Terms.
          </p>
          <p>
            We highly advise all Website Users to read this Terms and Conditions
            of Use page, as continued use of iGeekSoftware and its services, as well
            as the input of personal information, implies that you have read and
            understood this agreement (and all other regulations listed on this
            Website, including the Privacy Policy).
          </p>
          <h2 className="font-semibold">Third-Party Links & Ads</h2>
          <p>
            iGeekSoftware may contain links to third-party websites and services,
            and/or display advertisements for third-parties. Such Third-Party
            Links & Ads are not under the control of Company, and Company is not
            responsible for any Third-Party Links & Ads. Company provides access
            to these Third-Party Links & Ads only as a convenience to you, and
            does not review, approve, monitor, endorse, warrant, or make any
            representations with respect to Third-Party Links & Ads. You use all
            Third-Party Links & Ads at your own risk, and should apply a
            suitable level of caution and discretion in doing so. When you click
            on any of the Third-Party Links & Ads, the applicable third party's
            terms and policies apply, including the third party's privacy and
            data gathering practices.
          </p>
          <h2 className="font-semibold">Disclaimers</h2>
          <p>
            iGeekSoftware is provided on an "as-is" and "as available" basis, and
            company and our suppliers expressly disclaim any and all warranties
            and conditions of any kind, whether express, implied, or statutory,
            including all warranties or conditions of merchantability, fitness
            for a particular purpose, title, quiet enjoyment, accuracy, or
            non-infringement. We and our suppliers make no guarantee that the
            site will meet your requirements, will be available on an
            uninterrupted, timely, secure, or error-free basis, or will be
            accurate, reliable, free of viruses or other harmful code, complete,
            legal, or safe. If applicable law requires any warranties with
            respect to iGeekSoftware, all such warranties are limited in duration to
            ninety (90) days from the date of first use.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of implied warranties,
            so the above exclusion may not apply to you. Some jurisdictions do
            not allow limitations on how long an implied warranty lasts, so the
            above limitation may not apply to you.
          </p>
          <h2 className="font-semibold">Indemnification</h2>
          <p>
            You are holding the Company and its parent company, all affiliates,
            and individual members, subsidiaries, employees, directors, agents,
            co-partners, and bankers harmless by continuing to use iGeekSoftware and
            the Company's services. You agree not to bring any liabilities or
            claims against iGeekSoftware as a result of: i&#41; Your inappropriate or
            improper use of iGeekSoftware, ii&#41; Your violation of these Terms and
            Conditions of Use, or iii) any disagreement between You and a third
            party. If You file a claim or a disagreement, the terms of this
            clause may be invoked.
          </p>
          <h2 className="font-semibold">Limitation on Liability</h2>
          <p>
            To the maximum extent permitted by law, in no event shall company or
            our suppliers be liable to you or any third-party for any lost
            profits, lost data, costs of procurement of substitute products, or
            any indirect, consequential, exemplary, incidental, special or
            punitive damages arising from or relating to these terms or your use
            of, or incapability to use iGeekSoftware even if company has been advised
            of the possibility of such damages. Access to and use of iGeekSoftware is
            at your own discretion and risk, and you will be solely responsible
            for any damage to your device or computer system, or loss of data
            resulting therefrom.
          </p>
          <p>
            Some jurisdictions do not allow the limitation or exclusion of
            liability for incidental or consequential damages, so the above
            limitation or exclusion may not apply to you.
          </p>
          <p>
            Term and Termination. Subject to this Section, these Terms will
            remain in full force and effect while you use iGeekSoftware. We may
            suspend or terminate your rights to use iGeekSoftware at any time for any
            reason at our sole discretion, including for any use of iGeekSoftware in
            violation of these Terms. Upon termination of your rights under
            these Terms, your Account and right to access and use iGeekSoftware will
            terminate immediately. You understand that any termination of your
            Account may involve deletion of your User Content associated with
            your Account from our live databases. Company will not have any
            liability whatsoever to you for any termination of your rights under
            these Terms.
          </p>
          <h2 className="font-semibold">Contact Information</h2>
          <p>
            Email:{" "}
            <a
              href="mailto:support@igeeksoftware.com"
              className="text-orange-light hover:underline"
            >
              support@igeeksoftware.com
            </a>
          </p>
        </article>
      </div>
      <Footer />
    </>
  );
}
